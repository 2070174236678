import React, { useEffect } from 'react'
import Layout from "../../share/layout";
import '../../../styles/font-awesome-4.7.0/css/font-awesome.css'
import Image0 from '../../../images/sonidosaudiovisuales/face.png'
import Image1 from '../../../images/sonidosaudiovisuales/artista.png'
import Image2 from '../../../images/sonidosaudiovisuales/back.png'
import styles from '.././global.css'
import '.././movil.css'

const Emilio = () => {
    useEffect(() => {
        document.body.classList.add('body-sonidos-visuales');
        return function cleanup() {
            document.body.classList.remove('body-sonidos-visuales');
        };
    }, [])
    return (
        <Layout
            title="Geografías | UPC Cultural"
            metaTitle="Geografías | UPC Cultural"
            description="Exhibición multimedia que presenta seis propuestas de artistas peruanos contemporáneos. "
            subtitile="Exhibición multimedia que presenta seis propuestas de artistas peruanos contemporáneos."
            metaDescripcin="Exhibición multimedia que presenta seis propuestas de artistas peruanos contemporáneos."
            imgurl="//images.ctfassets.net/bv5rnjawitjg/E9aFHf11TxaEIX6JuU3vL/550f80daa53881d13f10dc95466806e2/800_x_800-1.png?h=300"
            keywords="Multimedia, Audiovisual, Luis Alvarado, Emilio Le Roux, Vered Engelhard, Luis Sanz, Paola Torres Núñez del Prado, Jonathan Castro Alejos, Lucía Beaumont."
        >
            <section id="section-content" class="section-content">
                <div class="region-inner region-content-inner">
                    <div class="face"><a href="https://www.facebook.com/upccultural1/" target="_blank"><img src={Image0} /></a></div>
                    <div id="page2" className="page page2 pdetalles" >
                        <div className="inner">
                            <span className="bg1"></span>
                            <span className="bg2"></span>
                            <span className="bglogo2"><span className="logoimg"></span></span>
                            <div className="row">
                                <div className="col cleft">
                                    <div className="video">
                                        <iframe id="video7" src="https://player.vimeo.com/video/694638016?h=927deaa59e&color=606060&title=0&byline=0&portrait=0" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                </div>
                                <div className="col cright">
                                    <div className="artista"><img src={Image1} /></div>
                                    <div className="titulo">
                                        <div className="nombre"><b>Jonathan Castro Alejos</b></div>
                                        <div className="obra"><b>Obra:</b> the:locus:</div>
                                    </div>
                                    <div className="detalle">
                                        <p>the:locus: es una documentación radical de las ruinas urbanas. Cada entorno se compone de un ensamblaje y reensamblaje digital de grabaciones sonoras y fotografías de los lugares visitados como evidencia material para exponer y ampliar el aura, las cualidades acústicas y visuales más allá de sus límites comunes.</p>
                                        <p>Biografía<br />
                                            Jonathan Castro Alejos es un artista visual y sonoro que vive y trabaja entre Ámsterdam y Lima. Su práctica oscila entre lo real y lo ficticio, ensamblando y reensamblando materias primas encontradas y colectadas con diversas cualidades e identidades para así reconstruirlas en nuevas superficies, lenguajes visuales o paisajes sonoros. Su trabajo ha sido expuesto y performado en Europa, Asia y Sudamérica. Como artista visual, ha trabajado para diferentes sellos discográficos y artistas como Huerco S, JASSS, Mannequin Records, Incienso, Berghain, AD93, Clone Records, Pinkman, Buh Records, entre otros. También ha trabajado con clientes como Nike, 032c, Boiler Room, Hypebeast, Randomevent, Van Gogh Museum, Metahaven, Bureau Borsche, Studio Dumbar, entre otros. Ha sido residente de Jan van Eyck Academie en Maastricht y actualmente se encuentra terminando un MA en Sandberg Instituut de Amsterdam.<br />
                                            <a href="https://www.jonathancastro.pe/" target="_blank">https://www.jonathancastro.pe/</a></p>
                                    </div>
                                    <a className="btnback" href='/galeria/geografias'>
                                        <a data-page="p0"><img src={Image2} /></a>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </Layout>
    )
}

export default Emilio